import React from 'react';

import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';

import PhotosData from "../../data/Photos.json";

export default class PhotoGallery extends React.Component {

    render() {
        return (
            <>
                <Jumbotron className="jumb jumbotron-photogallery" fluid></Jumbotron>
                <Container className="mb-5">
                    <Row className="mb-2">
                        <Col className="mt-3">
                            <h3 className="text-dark-green font-bold-9">Fotogalerie</h3>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <p>Nahlédněte jak to u nás vypadá, jak příjemně můžete strávit svojí dovolenou v srdci Železných hor.</p>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Interiér apartmánu</h3>
                            <p className="font-size-14">Podívejte se, kde budete spát, kde se osvěžíte v horkém letním dni, případně kde ukojíte svůj mlsný jazýček.</p>
                            <div>
                                <ImageGallery items={PhotosData.interier} showPlayButton={false} showBullets autoPlay/>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Venkovní prostory</h3>
                            <p className="font-size-14">Využít můžete i venkovních prostor přilehlých k apartmánu, např. terasa s el. grilem a slunečníkem, nebo si opéct něco dobrého na venkovním ohništi s grilem a děti se mohou zabavit na trampolíně.</p>
                            <div>
                                <ImageGallery items={PhotosData.exterier} showPlayButton={false} showBullets autoPlay/>
                            </div>
                        </Col>                   
                    </Row>
                </Container>
            </>
        );
    }
}