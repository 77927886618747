import React from 'react';

import { Container, Jumbotron, Row, Col, ListGroup } from 'react-bootstrap';

import { Tooltip } from '@material-ui/core';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';

import ReferenceData from '../../data/References.json';
import ReferenceForm from '../common/ReferenceForm';

import mapy from '../../images/thumbnail/mapy-cz.png';
import booking from '../../images/thumbnail/booking-com.png';
import eChalupy from '../../images/thumbnail/e-chalupy-cz.png';

export default class References extends React.Component {

    renderReferences(items: any[]) {
        return (
            <>
                {items.map((item) => (
                    <Col lg={6} className="m3-2 mt-3">
                        <ListGroup>
                                <ListGroup.Item className="listgroup-header">
                                    <AccountCircleTwoToneIcon className="mr-3 text-gray"></AccountCircleTwoToneIcon>
                                    <span className="text-dark-green font-bold-6">{item.name}</span>
                                </ListGroup.Item>
                                <ListGroup.Item className="font-size-13">
                                    <p className="font-size-13">{item.text}</p>
                                </ListGroup.Item>
                            </ListGroup>
                    </Col>
                ))}
            </>
        )
    };

    render() {
        return (
            <>
                <Jumbotron className="jumb jumbotron-references" fluid></Jumbotron>
                <Container className="mb-5">
                    <Row className="mb-3">
                        <Col className="mt-5">
                            <h3 className="text-dark-green font-bold-9">Reference</h3>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <p>Pročtěte si jak se u nás líbilo našim klientům. Uvidíte, že dovolenou u nás si opravdu můžete odpočinout.</p>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Jak nás vidí jinde</h3>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col lg={6}>
                            <p>Přečtěte si také reference či recenze, které o nás kolují po internetu.</p>
                        </Col>
                        <Col lg={1}></Col>
                        <Col className="mt-2">
                            <Tooltip title="Reference na Mapy.cz" placement="top" arrow>
                                <a href="https://en.mapy.cz/zakladni?x=15.6550306&y=49.8441294&z=17&source=firm&id=13205462" target="_blank" className="hyperlink-notunderline">
                                    <img src={mapy} alt="Reference na Mapy.cz" style={{ backgroundColor: "#9bbf5a" }} />
                                </a>
                            </Tooltip>
                        </Col>
                        <Col className="mt-2">
                            <Tooltip title="Reference na Booking.com" placement="top" arrow>
                                <a href="https://www.booking.com/hotel/cz/apartman-sec-sec.html?aid=808251#tab-reviews" target="_blank" className="hyperlink-notunderline">
                                    <img src={booking} alt="Reference na Booking.com" />
                                </a>
                            </Tooltip>
                        </Col>
                        <Col className="mt-2">
                            <Tooltip title="Reference na e-chalupy.cz" placement="top" arrow>
                                <a href="https://www.e-chalupy.cz/vychodni_cechy/apartman-sec-3209.php#hodnoceni" target="_blank" className="hyperlink-notunderline">
                                    <img src={eChalupy} alt="Reference na e-chalupy.cz" />
                                </a>
                            </Tooltip>
                        </Col>
                        <Col lg={3}></Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Řekli přímo nám</h3>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        {this.renderReferences(ReferenceData)}
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Napište nám vaše zkušenosti</h3>
                            <p>Dejte nám vědět, jak se Vám u nás líbilo. Vítáme i podněty pro zlepšení či změnu. Děkujeme Vám za zprávu.</p>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <ReferenceForm></ReferenceForm>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}