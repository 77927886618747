import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, NavLink } from 'react-router-dom';

import { ReactComponent as ToTopSVG } from "./images/common/go-to-top.svg";
import ScrollToTop from "react-scroll-to-top";

import AppInfo from './data/AppInfo.json';

import Menu from './components/layout/Menu';
import Body from './components/layout/Body';
import Footer from './components/layout/Footer';

import { Button, Snackbar, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Cookies from 'js-cookie';

const cookiesKey = 'apartman-sec-cookies';

interface IAppProps { }
interface IAppState {
    allowedCookies: string;
}

export default class App extends Component<IAppProps, IAppState> {
 
  constructor(props: Readonly<IAppProps>) {
    super(props);

    this.state = {
        allowedCookies: 'disabled'
    };

    this.allowCookies = this.allowCookies.bind(this);
  }

  componentDidMount() {
    let cookiesValue = Cookies.get(cookiesKey);
    
    if (cookiesValue !== undefined && cookiesValue !== '') { 
      this.setState({ allowedCookies: cookiesValue });  
    }
  }

  allowCookies() {
    this.setState({ allowedCookies: 'allowed' }); 
    Cookies.set(cookiesKey, 'allowed', { expires: 365, path: '' });
  }

  render() {
    return (
      <Router>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{AppInfo.Name}</title>
          <meta name="description" content={AppInfo.Description} />
          <link rel="home" href={AppInfo.Url}></link>
          <link rel="icon" type="image/png" sizes="32x32" href="./images/common/favicon-32x32.png"></link>
          <link rel="icon" type="image/png" sizes="16x16" href="./images/common/favicon-16x16.png"></link>
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          <meta name="msapplication-TileColor" content="#da532c"></meta>
          <meta name="theme-color" content="#ffffff"></meta>
        </Helmet>

        <ScrollToTop smooth top={50} component={ <Tooltip title="Posunout nahoru" aria-label="Nahoru"><ToTopSVG /></Tooltip> } />

        <Menu></Menu>
        <Body></Body>
        <Footer></Footer>

        <Snackbar open={this.state.allowedCookies !== 'allowed'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert severity="warning" variant="filled">
            Naše internetové stránky používají soubory cookie. Více informací <NavLink className="mt-0 pt-0 hyperlinkWhite" to="/ostatni/prohlaseni-o-pouzivani-cookies"> zde.</NavLink>
            <Button className="ml-5" size="small" onClick={this.allowCookies}>Souhlasím s použitím</Button>
          </Alert>
        </Snackbar>
      </Router>
    );
  }
}
