import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Accommodation from '../pages/Accommodation';
import Contact from '../pages/Contact';
import Home from '../pages/Home';
import PhotoGallery from '../pages/PhotoGallery';
import PriceList from '../pages/PriceList';
import References from '../pages/References';
import Surroundings from '../pages/Surroundings';

import PageDoesntExist from '../layout/PageDoesntExist';
import CookieInformations from '../layout/CookieInformations';


export default class Body extends React.Component {

    render() {
        return (
            <>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/ubytovani" component={Accommodation} />
                    <Route path="/okoli" component={Surroundings} />
                    <Route path="/fotogalerie" component={PhotoGallery} />
                    <Route path="/cenik" component={PriceList} />
                    <Route path="/reference" component={References} />
                    <Route path="/kontakt" component={Contact} />

                    <Route path="/ostatni/prohlaseni-o-pouzivani-cookies" component={CookieInformations} />

                    <Route component={PageDoesntExist} />
                </Switch>
            </>
        );
    }
}