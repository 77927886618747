import React from 'react';

import { Container, Jumbotron, Row, Col } from 'react-bootstrap';

import EmailForm from '../common/EmailForm';

import ContactData from '../../data/Contact.json';

export default class Contact extends React.Component {

    render() {
        return (
            <>
                <Jumbotron className="jumb jumbotron-contact" fluid></Jumbotron>
                <Container className="mb-5">
                    <Row className="mb-3">
                        <Col className="mt-5">
                            <h3 className="text-dark-green font-bold-9">Kontakt</h3>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <p>Napište nám, zavolejte nám, ideálně se přijeďte rovnou podívat.</p>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h1 className="text-dark-gray font-bold-3">
                                <span>Apartmán</span><span className="text-green">Seč</span>
                            </h1>
                            <span className="text-gray font-size-16">... rodinné ubytování u Sečské přehrady</span>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={6}>
                            <div className="ml-5 mt-5">
                                <div className="mt-3">
                                    <p className="font-bold-6 mb-0 text-dark-green font-size-18">{ContactData.owner}</p>
                                    <div className="font-size-13">
                                        <p>
                                            {"IČ: " + ContactData.ico} 
                                            <br />
                                            {"DIČ: " + ContactData.vat}
                                            <br />
                                            <span className="font-size-10">{ContactData.register}</span>
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="font-size-13">
                                        <p className="mb-0">
                                            tel: 
                                            <a href={"tel: " + ContactData.mobile} className="hyperlink ml-3">{ContactData.mobile}</a>
                                        </p>
                                        <p>
                                            email: 
                                            <a href={'mailto:' + ContactData.email} className="hyperlink ml-3">{ContactData.email}</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <p className="font-bold-6 mb-0">Kde nás najdete</p>
                                    <div className="font-size-13">
                                        <p className="mb-0">{ContactData.address1}</p>
                                        <p>{ContactData.address2}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <p className="font-bold-6">Napište nám</p>
                            <EmailForm></EmailForm>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <iframe src="https://frame.mapy.cz/s/nogepomevo" allowFullScreen className="mapa mapa-contact" ></iframe>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}