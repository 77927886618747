import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

interface ICookieInformationsProps { }
interface ICookieInformationsState {

}

export default class CookieInformations extends Component<ICookieInformationsProps, ICookieInformationsState> {

    constructor(props: Readonly<ICookieInformationsProps>) {
        super(props);

        this.state = {

        };

    }


    render() {
        return (
            <div>
                <Container className="mt-4 mb-5">
                    <Row>
                        <Col lg={12} className="mb-3 mt-4">
                            <div className="mb-5">
                                <h2 className="mb-4 text-red">Co jsou cookies</h2>
                                <p>Cookies jsou krátké textové soubory vytvářené webovým serverem a ukládané ve Vašem počítači prostřednictvím prohlížeče. Když se později vrátíte na stejný web, prohlížeč pošle uloženou cookie zpět a server tak získá všechny informace, které si u vás předtím uložil. Cookies využívá pro svou činnost naprostá většina webových stránek.</p>
                            </div>
                            <div className="mb-5">
                                <h2 className="mb-4 text-red">Jak se dělí cookies</h2>
                                <p>Cookies lze rozdělit podle toho, kdo je k Vám na web umisťuje, tj. na:</p>
                                <ul>
                                    <li>Cookie první strany (first party cookie) – jejich platnost je omezena na doménu webu, který prohlížíte. Tyto cookies jsou považovány za bezpečnější.</li>
                                    <li>Cookie třetí strany (third party cookie) – jsou umístěny pomocí skriptu z jiné domény. Uživatele tak lze sledovat napříč doménami. Používají se často pro vyhodnocení účinnosti reklamních kanálů.</li>
                                </ul>
                                <p>Podle trvanlivosti lze cookies rozdělit na:</p>
                                <ul>
                                    <li>Krátkodobé (session cookie) – vymažou se z vašeho počítače po zavření prohlížeče.</li>
                                    <li>Dlouhodobé (persistent cookie) – po zavření prohlížeče zůstávají zachovány, vymažou se teprve po uplynutí velmi dlouhé doby (ta záleží na nastavení Vašeho prohlížeče a nastavení cookie). Můžete je také ručně odstranit.</li>
                                </ul>
                            </div>
                            <div className="mb-5">
                                <h2 className="mb-4 text-red">K čemu cookies používáme</h2>
                                <p>Na našem webu používáme tyto cookies:</p>
                                <ul>
                                    <li>Technické – první strany, krátkodobé. Zajišťují základní technickou funkčnost webu, tj. přihlašování, využívání služeb apod.</li>
                                    <li>Google Analytics – první strany, dlouhodobé. Jsou využity ke generování anonymních statistik o používání webu.</li>
                                    <li>Hotjar – první strany, krátkodobé i dlouhodobé. Pro analýzu návštěvnosti a zlepšení ovladatelnosti tohoto webu používáme nástroj Hotjar.</li>
                                </ul>
                                <p><strong>Do cookies nikdy neumisťujeme citlivá nebo osobní data.</strong></p>
                            </div>
                            <div className="mb-5">
                                <h2 className="mb-4 text-red">Jak lze upravit využívání cookies</h2>
                                <h4>Vymazání</h4>
                                <p>Vymazat můžete cookies ve Vašem prohlížeči – zpravidla bývá umístěno v „Historii“ navštívených stránek.</p>
                                <h4>Blokování</h4>
                                <p>Prohlížeče umožňují umísťování cookies na Vás počítač zablokovat. V takovém případě bude ale funkcionalita těchto stránek omezena. Informace o nastavení ukládání souborů cookies ve Vašem prohlížeči najdete na stránkách poskytovatele konkrétního prohlížeče:</p>
                                <ul>
                                    <li><a rel="noopener noreferrer" className="hyperlink" href="https://support.microsoft.com/cs-cz/search?query=enable%20cookies%20in%20edge" target="_blank">Microsoft Edge</a></li>
                                    <li><a rel="noopener noreferrer" className="hyperlink" href="https://support.google.com/accounts/answer/61416?hl=cs" target="_blank">Google Chrome</a></li>
                                    <li><a rel="noopener noreferrer" className="hyperlink" href="https://support.mozilla.org/cs/kb/Pr%C3%A1ce%20s%20cookies" target="_blank">Mozilla Firefox</a></li>
                                    <li><a rel="noopener noreferrer" className="hyperlink" href="http://support.microsoft.com/gp/cookies/cs" target="_blank">Microsoft Internet Explorer</a></li>
                                    <li><a rel="noopener noreferrer" className="hyperlink" href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">Apple Safari on Mac</a></li>
                                    <li><a rel="noopener noreferrer" className="hyperlink" href="https://support.google.com/xoom/answer/169022?rd=1" target="_blank">Android</a></li>
                                    <li><a rel="noopener noreferrer" className="hyperlink" href="https://support.apple.com/guide/mdm/managing-safari-cookies-mdmf7d5714d4/web" target="_blank">Apple Safari on iPhone</a></li>
                                </ul>
                                <p>Další informace o cookies a jejich využití najdete na stránkách <a href="http://aboutcookies.org/" className="hyperlink" target="_blank">AboutCookies.org</a>.</p>
                            </div>
                            <div className="mb-5">
                                <h2 className="mb-4 text-red">Tento web používá Google Analytics</h2>
                                <p>Tato stránka používá službu Google Analytics, poskytovanou společností Google, Inc. (dále jen "Google"). Služba Google Analytics používá souborů cookies. Informace o užívání stránky spolu s obsahem souboru cookie bude společností Google přenesen a uložen na serverech ve Spojených státech. Google bude užívat těchto informací pro účely vyhodnocování užívání stránky a vytváření zpráv o její aktivitě, určených pro její provozovatele, a pro poskytování dalších služeb týkajících se činností na stránce a užívání internetu vůbec. Google může také poskytnout tyto informace třetím osobám, bude-li to požadováno zákonem nebo budu-li takovéto třetí osoby zpracovávat tyto informace pro Google.</p>
                                <p>Služba Google Analytics je rozšířena o související reklamní funkce poskytované společností Google, a to:</p>
                                <ul>
                                    <li>Přehledy zobrazení v reklamní síti Google.</li>
                                    <li>Remarketing (zobrazování reklam v obsahové síti na základě zhlédnutých produktů).</li>
                                    <li>Rozšířené demografické přehledy (reportování anonymních demografických dat).</li>
                                </ul>
                                <p>Více informací o zpracování a využití dat najdete ve <a rel="noopener noreferrer" className="hyperlink" href="http://www.google.com/intl/cs/policies/privacy/partners/" target="_blank">smluvních podmínkách společnosti Google</a>.</p>
                            </div>
                            <div>
                                <h2 className="mb-4 text-red">Tento web používá Google Analytics</h2>
                                <p>Tato stránka používá službu Google Analytics, poskytovanou společností Google, Inc. (dále jen "Google"). Služba Google Analytics používá souborů cookies. Informace o užívání stránky spolu s obsahem souboru cookie bude společností Google přenesen a uložen na serverech ve Spojených státech. Google bude užívat těchto informací pro účely vyhodnocování užívání stránky a vytváření zpráv o její aktivitě, určených pro její provozovatele, a pro poskytování dalších služeb týkajících se činností na stránce a užívání internetu vůbec. Google může také poskytnout tyto informace třetím osobám, bude-li to požadováno zákonem nebo budu-li takovéto třetí osoby zpracovávat tyto informace pro Google.</p>
                                <p>Služba Google Analytics je rozšířena o související reklamní funkce poskytované společností Google, a to:</p>
                                <ul>
                                    <li>Přehledy zobrazení v reklamní síti Google.</li>
                                    <li>Remarketing (zobrazování reklam v obsahové síti na základě zhlédnutých produktů).</li>
                                    <li>Rozšířené demografické přehledy (reportování anonymních demografických dat).</li>
                                </ul>
                                <p>Pokud nechcete poskytovat anonymní data o používání webu službě Google Analytics, můžete použít <a rel="noopener noreferrer" className="hyperlink" href="https://tools.google.com/dlpage/gaoptout" target="_blank">plugin poskytovaný společností Google</a>. Po nainstalování do Vašeho prohlížeče a aktivaci nebudou dále data odesílána.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}