import React from "react";
import * as emailjsCom from 'emailjs-com';

import ContactData from '../../data/Contact.json';
import EmailConfig from '../../data/EmailConfiguration.json';
import { Button } from "react-bootstrap";

interface IEmailProcessor {
  sending: boolean,
  result: string
}
interface IValidations {
  showErrors: boolean,
  nameIsValid: boolean,
  emailIsValid: boolean,
  messageIsValid: boolean,
}
interface IMessage {
  name: string,
  email: string,
  mobil: string,
  message: string,
}

interface IEmailFormProps {
}

interface IEmailFormState {
  message: IMessage,
  validations: IValidations,
  emailProcessor: IEmailProcessor
}

export default class EmailForm extends React.Component<IEmailFormProps, IEmailFormState> {

  constructor(props: Readonly<IEmailFormProps>) {
    super(props);
    this.state = {
      message: {
        name: "",
        email: "",
        mobil: "",
        message: ""
      },
      validations: {
        showErrors: false,
        nameIsValid: false,
        emailIsValid: false,
        messageIsValid: false
      },
      emailProcessor: {
        sending: false,
        result: ""
      }
    }

    emailjsCom.init(EmailConfig.userId);

  }

  renderValidation(validationType: string) {
    if(!this.state.validations.showErrors) {
      return (<></>);
    }

    if (validationType === "name" && !this.state.validations.nameIsValid) {
      return (<p className="text-red font-bold-6">Jméno je povinné pole</p>);
    }

    if (validationType === "email" && !this.state.validations.emailIsValid) {
      return (<p className="text-red font-bold-6">Email je povinné pole</p>);
    }

    if (validationType === "message" && !this.state.validations.messageIsValid) {
      return (<p className="text-red font-bold-6">Zpráva je povinné pole</p>);
    }
  }

  renderEmailProcessor() {
    if (this.state.emailProcessor.result === "SUCCESS") {
      return (<span className="text-dark-green ml-4">Zprávu jste nám úspěšně odeslali. Ozveme se co nejdříve.</span>);
    }
    else if (this.state.emailProcessor.result === "FAILED") {
      return (<span className="text-red ml-4">Zpráva nebyla z technických důvodů odeslána. Prosím kontaktujete nás přímo na email {ContactData.email}</span>);
    }
    else { return (<></>); }
  }

  render() {
    return (
      <>
        <div>
          <div className="mb-3">
            <label htmlFor="name" className="font-bold-3">Vaše jméno *</label>
            <input type="text" className="form-control" value={this.state.message.name} onChange={this.onNameChange.bind(this)} />
            {this.renderValidation("name")}
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="font-bold-3">Emailová adresa *</label>
            <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.message.email} onChange={this.onEmailChange.bind(this)} />
            {this.renderValidation("email")}
          </div>
          <div className="mb-3">
            <label htmlFor="mobil" className="font-bold-3">Telefonní číslo</label>
            <input type="mobil" className="form-control" aria-describedby="mobileHelp" value={this.state.message.mobil} onChange={this.onMobilChange.bind(this)} />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="font-bold-3">Zpráva *</label>
            <textarea className="form-control" rows={5} value={this.state.message.message} onChange={this.onMessageChange.bind(this)} />
            {this.renderValidation("message")}
          </div>
          <Button variant="success" size="sm" className="custom-btn" disabled={this.state.emailProcessor.sending} onClick={this.handleSubmit.bind(this)}>
            {this.state.emailProcessor.sending ? "Odesílám..." : "Odeslat"}
          </Button>
          {this.renderEmailProcessor()}
        </div>
      </>
    );
  }

  onNameChange(event: { target: { value: any; }; }) {
    let message = this.state.message;
    message.name = event.target.value;

    let validations = this.state.validations;
    validations.nameIsValid = message.name !== "";

    this.setState({
      message: message,
      validations: validations
    });
  }

  onEmailChange(event: { target: { value: any; }; }) {
    let message = this.state.message;
    message.email = event.target.value;

    let validations = this.state.validations;
    validations.emailIsValid = message.name !== "";

    this.setState({
      message: message,
      validations: validations
    });
  }

  onMobilChange(event: { target: { value: any; }; }) {
    let message = this.state.message;
    message.mobil = event.target.value;

    this.setState({ message: message });
  }

  onMessageChange(event: { target: { value: any; }; }) {
    let message = this.state.message;
    message.message = event.target.value;

    let validations = this.state.validations;
    validations.messageIsValid = message.name !== "";

    this.setState({
      message: message,
      validations: validations
    });
  }

  handleSubmit(event: any) {
    let validations = this.state.validations;

    if (!validations.nameIsValid || !validations.emailIsValid || !validations.messageIsValid) {
      validations.showErrors = true;
      this.setState({ validations: validations });
    }
    else {
      let emailProcessor: IEmailProcessor = {
        sending: true,
        result: ""
      }

      this.setState({ emailProcessor: emailProcessor });

      emailjsCom.send(EmailConfig.serviceId, EmailConfig.templates.emailFormTemplateId, this.state.message, EmailConfig.userId)
        .then((response) => {

          let emailProcessor: IEmailProcessor = {
            sending: false,
            result: "SUCCESS"
          }

          let message: IMessage = {
            name: "",
            email: "",
            mobil: "",
            message: ""
          }

          this.setState({
            message: message,
            emailProcessor: emailProcessor
          });

          console.log('SUCCESS', response.status, response.text);
        }, (err) => {

          let emailProcessor: IEmailProcessor = {
            sending: false,
            result: "FAILED"
          }

          this.setState({
            emailProcessor: emailProcessor,
          });

          console.log('FAILED', err);
        });
    }
  }
}