import React, { Component } from 'react';
import { Container, Row, Col, Jumbotron, Button } from 'react-bootstrap';

export default class PageDoesntExist extends Component {
    render() {
        return (
            <Container fluid>
                <Row>
                    <Col lg={12} className="p-0">
                        <Jumbotron className="text-center m-0 bg-info d-flex flex-column justify-content-center bg-page-doesnt-exist">
                            <Container className="mb-5">
                                <Row className="m-5">
                                    <Col lg={2} className="text-right"></Col>
                                    <Col lg={8}>
                                        <span className="display-1 text-red font-weight-bold font-roboto-condensed mb-5">[ 404 ]</span>
                                        <h2 className="display-5 shadow-text-xs text-white font-weight-bold font-roboto-condensed mt-5">Je tu krásně že?</h2>
                                        <h4 className="shadow-text-xs text-white font-weight-bold font-roboto-condensed">To u nás je krásně všude, ale bohužel ne na této stránce...</h4>
                                    </Col>
                                    <Col lg={2} className="text-left"></Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Button href="/" variant="danger" >Zkuste se vrátit</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Jumbotron>
                    </Col>
                </Row>
            </Container>
        );
    }
}