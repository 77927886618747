import React from 'react';

import * as AppInfo from '../../data/AppInfo.json';
import logo from './../../images/common/logo.png';

import { Navbar, Container, Nav } from 'react-bootstrap';
import { Tooltip } from '@material-ui/core';

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

export default class Menu extends React.Component {

    getClassForMenuLink(path: string) {
        var currentRoutes = window.location.href;
        console.log(currentRoutes);
        if (currentRoutes === AppInfo.Url + path) {
            return "nav-link-active"
        }
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="lg" sticky="top" className="nav-big nav-bg" >
                <Container>
                    <Navbar.Brand href="/" className="pull-right">
                        <img
                            src={logo}
                            className="d-inline-block align-center mb-2"
                            alt={AppInfo.Name}
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto text-right">
                            <Nav.Link href="/" className={"mr-3 font-roboto-condensed font-size-13 font-weight-bold " + this.getClassForMenuLink("/")}>
                                <Tooltip title={"Zpět na úvodní stránku " + AppInfo.Name} placement="top" arrow>
                                    <HomeOutlinedIcon></HomeOutlinedIcon>
                                </Tooltip>
                            </Nav.Link>
                            <Nav.Link href="/ubytovani" className={"mr-3 font-roboto-condensed font-size-15 font-weight-bold  " + this.getClassForMenuLink("/ubytovani")}>
                                UBYTOVÁNÍ
                            </Nav.Link>
                            <Nav.Link href="/okoli" className={"mr-3 font-roboto-condensed font-size-15 font-weight-bold  " + this.getClassForMenuLink("/okoli")}>
                                OKOLÍ
                            </Nav.Link>
                            <Nav.Link href="/fotogalerie" className={"mr-3 font-roboto-condensed font-size-15 font-weight-bold  " + this.getClassForMenuLink("/fotogalerie")}>
                                FOTOGALERIE
                            </Nav.Link>
                            <Nav.Link href="/cenik" className={"mr-3 font-roboto-condensed font-size-15 font-weight-bold  " + this.getClassForMenuLink("/cenik")}>
                                CENÍK
                            </Nav.Link>
                            <Nav.Link href="/reference" className={"mr-3 font-roboto-condensed font-size-15 font-weight-bold  " + this.getClassForMenuLink("/reference")}>
                                REFERENCE
                            </Nav.Link>
                            <Nav.Link href="/kontakt" className={"mr-3 font-roboto-condensed font-size-15 font-weight-bold  " + this.getClassForMenuLink("/kontakt")}>
                                KONTAKT
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}