import React from 'react';

import { Container, Jumbotron, Row, Col, Card, Table, ListGroup, Form, Button } from 'react-bootstrap';

import thumb_adult from '../../images/thumbnail/pricelist-adult.jpg';
import thumb_child from '../../images/thumbnail/pricelist-child.jpg';

import PriceData from '../../data/Pricelist.json';
import CalculatorData from '../../data/Calculator.json';

interface IPriceListProps { }
interface IPriceListState {
    nightCount: number,
    adultCount: number,
    childTo3Count: number,
    childFrom3To12Count: number,
    childFrom12Count: number,

    finalAdultPrice: number,
    finalChildTo3Price: number,
    finalChildFrom3To12Price: number,
    finalChildFrom12Price: number,
    finalSales: number,
    finalExpandables: number,
    sumPrice: number,
}

export default class PriceList extends React.Component<IPriceListProps, IPriceListState> {

    constructor(props: Readonly<IPriceListProps>) {
        super(props);
        this.state = {
            nightCount: 0,
            adultCount: 0,
            childTo3Count: 0,
            childFrom3To12Count: 0,
            childFrom12Count: 0,

            finalAdultPrice: 0,
            finalChildTo3Price: 0,
            finalChildFrom3To12Price: 0,
            finalChildFrom12Price: 0,
            finalSales: 0,
            finalExpandables: 0,
            sumPrice: 0
        }
    }

    renderPrices(prices: any[]) {
        return (
            <Table hover size="sm" responsive="lg">
                <tbody>
                    {prices.map((price, index) => (
                        <tr>
                            <td>{price.nazev}</td>
                            <td className="font-bold-6">{price.cena}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    renderSalesAndTaxes(values: string[]) {
        return (
            <ul>
                {values.map((value, index) => (
                    <li className="font-size-13">{value}</li>
                ))}
            </ul>
        );
    }

    calculateFullPrice() {
        let saleSum = 0;
        let expendSum = 0;

        let finalAdultPrice = this.state.adultCount === 1 ? CalculatorData.dospeli.prvni : this.state.adultCount * CalculatorData.dospeli.dalsi + (CalculatorData.dospeli.prvni - CalculatorData.dospeli.dalsi);
        let finalChildTo2Price = CalculatorData.dite.do2let * this.state.childTo3Count;
        let finalChildFrom3To12Price = CalculatorData.dite.od2do10 * this.state.childFrom3To12Count;
        let finalChildFrom12Price = CalculatorData.dite.od10 * this.state.childFrom12Count;

        let sumPrice = finalAdultPrice + finalChildTo2Price + finalChildFrom3To12Price + finalChildFrom12Price;
        let saleSumPercent = 0;

        if (this.state.nightCount >= 5) {
            saleSumPercent = saleSumPercent + CalculatorData.slevy['5noci'];
        }

        if (saleSumPercent !== 0) {
            saleSum = (sumPrice / 100) * saleSumPercent;
            sumPrice = sumPrice - saleSum;
        }

        if (this.state.nightCount === 1) {
            expendSum = (sumPrice / 100) * CalculatorData.prirazky.jednaNoc;
            sumPrice = sumPrice + expendSum;
        }
        else {
            sumPrice = this.state.nightCount * sumPrice;
        }

        this.setState({
            finalAdultPrice: finalAdultPrice,
            finalChildTo3Price: finalChildTo2Price,
            finalChildFrom3To12Price: finalChildFrom3To12Price,
            finalChildFrom12Price: finalChildFrom12Price,
            finalSales: saleSum,
            finalExpandables: expendSum,
            sumPrice: sumPrice,
        });
    }

    componentDidMount() {
        this.calculateFullPrice();
    }

    numberWithSpaces(x: number) {
        x = Math.round(x);
        var parts = x.toString();
        return parts.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    render() {
        return (
            <>
                <Jumbotron className="jumb jumbotron-pricelist" fluid></Jumbotron>
                <Container className="mb-5">
                    <Row className="mb-3">
                        <Col className="mt-5">
                            <h3 className="text-dark-green font-bold-9">Ceník ubytování</h3>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <p>Cena pobytu za jednu noc - je včetně rekreačního poplatku. Minimální doba rezervace jsou dvě noci.</p>
                            <p><span className="font-bold-6 text-green">Dárek  -  láhev vína</span>,  při pobytu na <span className="font-bold-6 text-green">4 a více nocí.</span></p>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <p>Ubytování je možné věnovat i v podobě <span className="font-bold-6 text-green">Voucheru (dárkového poukazu)</span>.</p>
                            <p>Pro více informací nás prosím &nbsp;<a className="font-roboto-condensed hyperlink font-bold-6" href="/kontakt">kontaktujte</a>.</p>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Podmínky</h3>
                            <div className="mt-3">
                                <p className="font-bold-6 mb-0">Platba za pobyt</p>
                                <p className="font-size-13">Platba bude provedena na základě potvrzené objednávky a vystavené faktury převodem na účet, dle platebních podmínek faktury.</p>
                            </div>
                            <div className="mt-3">
                                <p className="font-bold-6 mb-0">Vratná kauce</p>
                                <p className="font-size-13">Při nástupu na pobyt se hradí 1 500,-Kč v hotovosti, která se při odjezdu vrací.</p>
                            </div>
                            <div className="mt-3">
                                <p className="font-bold-6 mb-0">Zrušení rezervace</p>
                                <p className="font-size-13">Rezervaci je možné zrušit zdarma do 14 dnů před datem nástupu. V případě zrušení rezervace méně než 14 dní před datem nástupu, Vám bude účtováno 50% z celkové ceny pobytu. Pokud se k pobytu nedostavíte, bude Vám účtováno 100% celkové ceny pobytu.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Ceník</h3>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={6} className="mb-4">
                            <Card className="card-hover">
                                <Card.Img variant="top" src={thumb_adult} />
                                <Card.Body>
                                    <Card.Title className="text-dark-green mb-0">Dospělí</Card.Title>
                                    <Card.Text className="text-dark-gray">
                                        <p className="mb-1 mt-5 font-size-13">
                                            {this.renderPrices(PriceData.dospeli)}
                                        </p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card className="card-hover">
                                <Card.Img variant="top" src={thumb_child} />
                                <Card.Body>
                                    <Card.Title className="text-dark-green mb-0">Děti</Card.Title>
                                    <Card.Text className="text-dark-gray">
                                        <p className="mb-1 mt-3 font-size-13">
                                            {this.renderPrices(PriceData.deti)}
                                        </p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Dodatky k ceně</h3>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col lg={6} className="mb-3">
                            <p className="font-bold-6 mb-0">Slevy</p>
                            {this.renderSalesAndTaxes(PriceData.slevy)}
                        </Col>
                        <Col lg={6}>
                            <p className="font-bold-6 mb-0">Přirážky</p>
                            {this.renderSalesAndTaxes(PriceData.prirazky)}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Cenová kalkulačka ubytování</h3>
                            <p className="font-size-13">
                                Cena je pouze orientační a není závazná. Pro přesnou kalkulaci či případnou objednávku ubytování nás prosím
                                &nbsp;<a className="font-roboto-condensed hyperlink font-bold-6" href="/kontakt">kontaktujte</a>.
                            </p>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col lg={6} className="mb-3">
                            <p className="font-bold-6 mb-0 mt-4">Parametry pobytu</p>
                            <p className="font-size-12">* dítě uvádějte do kategorie dle stáří v den nástupu na pobyt</p>
                            <Container className="font-size-13">
                                <Row className="mt-2">
                                    <Col><span>Počet nocí</span></Col>
                                    <Col>
                                        <input type="number" min="1" className="form-control font-size-13" value={this.state.nightCount} onChange={this.onNightCountChange.bind(this)} />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col><span>Počet dospělých</span></Col>
                                    <Col>
                                        <input type="number" min="1" className="form-control font-size-13" value={this.state.adultCount} onChange={this.onAdultCountChange.bind(this)} />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col><span>Počet dětí do 2 let</span></Col>
                                    <Col>
                                        <input type="number" min="0" className="form-control font-size-13" value={this.state.childTo3Count} onChange={this.onChildTo3CountChange.bind(this)} />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col><span>Počet dětí od 2 do 10 let</span></Col>
                                    <Col>
                                        <input type="number" min="0" className="form-control font-size-13" value={this.state.childFrom3To12Count} onChange={this.onChildFrom3To12CountChange.bind(this)} />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col><span>Počet dětí od 10 let</span></Col>
                                    <Col>
                                        <input type="number" min="0" className="form-control font-size-13" value={this.state.childFrom12Count} onChange={this.onChildFrom12CountChange.bind(this)} />
                                    </Col>
                                </Row>
                                <Row className="mt-2 text-right">
                                    <Col>
                                        <Button variant="success" size="sm" className="custom-btn" onClick={this.calculateFullPrice.bind(this)}>Spočítat</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col lg={6}>
                            <ListGroup className="mt-3">
                                <ListGroup.Item className="listgroup-header font-size-14">Orientační cena pobytu</ListGroup.Item>
                                <ListGroup.Item>
                                    <div className="mt-5">
                                        <p className="w-100 font-bold-3 text-dark-green font-size-40 text-center mb-5" >{this.numberWithSpaces(this.state.sumPrice) + " Kč *"}</p>
                                        <hr></hr>
                                        <div className="font-size-13 mt-4">
                                            {/* <p >* výpočet neobsahuje slevu za pobyt v červnu a září</p> */}
                                            <p className="m-0">* výpočet neobsahuje slevu za Senior Pas a Rodinný pas</p>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    onNightCountChange(event: { target: { value: any; }; }) {
        this.setState({ nightCount: +event.target.value });
    }

    onAdultCountChange(event: { target: { value: any; }; }) {
        this.setState({ adultCount: +event.target.value });
    }

    onChildTo3CountChange(event: { target: { value: any; }; }) {
        this.setState({ childTo3Count: +event.target.value });
    }

    onChildFrom3To12CountChange(event: { target: { value: any; }; }) {
        this.setState({ childFrom3To12Count: +event.target.value });
    }

    onChildFrom12CountChange(event: { target: { value: any; }; }) {
        this.setState({ childFrom12Count: +event.target.value });
    }
}