import React from 'react';

import { Container, Row, Col, Jumbotron, Card } from 'react-bootstrap';
import { Tooltip } from '@material-ui/core';

import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';

import thumb_bara from '../../images/thumbnail/bara.jpg';
import thumb_barbora from '../../images/thumbnail/barbora.jpg';
import thumb_kostnice from '../../images/thumbnail/kostnice.jpg';
import thumb_zleby from '../../images/thumbnail/zleby.jpg';
import thumb_pardubice from '../../images/thumbnail/pardubice.jpg';
import thumb_loutky from '../../images/thumbnail/loutky.jpg';
import thumb_stribro from '../../images/thumbnail/stribro.jpg';
import thumb_kopec from '../../images/thumbnail/vesely-kopec.jpg';
import thumb_mapy from '../../images/thumbnail/mapy-cz.png';

import BikeMapsData from '../../data/BikeMaps.json';

export default class Surroundings extends React.Component {

    getDificultAsString(dificult: number) {
        if (dificult === 1) { return "Nulová" }
        if (dificult === 2) { return "Nízká" }
        if (dificult === 3) { return "Střední" }
        if (dificult === 4) { return "Vysoká" }
        if (dificult === 5) { return "Hustodémonskykrutopřísná" }

        return "Nepopsatelná";
    }

    renderBikeDificulty(dificult: number) {
        if (dificult === 0) {
            return (
                <>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                </>
            )
        }
        if (dificult === 1) {
            return (
                <>
                    <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                </>
            )
        }
        if (dificult === 2) {
            return (
                <>
                    <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                </>
            )
        }
        if (dificult === 3) {
            return (
                <>
                    <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                </>
            )
        }

        if (dificult === 4) {
            return (
                <>
                    <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                    <DirectionsBikeIcon className="mr-1 text-gray"></DirectionsBikeIcon>
                </>
            )
        }

        return (
            <>
                <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
                <DirectionsBikeIcon className="mr-1"></DirectionsBikeIcon>
            </>
        )
    }

    renderBikeTips() {
        return (
            <>
                {
                    BikeMapsData.map((item, index) => (
                        <Col lg={4} className="mb-4">
                            <a href={item.mapyUrl} target="_blank" className="hyperlink-notunderline">
                                <Card className="card-hover">
                                    <Card.Body>
                                        <Card.Title className="text-dark-green mb-2">{item.title}</Card.Title>
                                        <Card.Text className="text-dark-gray font-size-13">
                                            <p className="mb-1">{item.description}</p>
                                            <hr></hr>
                                            <p className="font-bold-6">{item.measure}</p>
                                            <hr></hr>
                                        </Card.Text>
                                        <Card.Footer style={{ backgroundColor: "#9bbf5a" }}>
                                            <table className="w-100">
                                                <tr>
                                                    <td className="text-left">
                                                        <Tooltip title={this.getDificultAsString(item.difficult) + " obtížnost"} placement="top" arrow>
                                                            <div className="text-light-gray">
                                                                {this.renderBikeDificulty(item.difficult)}
                                                            </div>
                                                        </Tooltip>
                                                    </td>
                                                    <td className="text-right">
                                                        <img src={thumb_mapy}></img>
                                                    </td>
                                                </tr>
                                            </table>
                                        </Card.Footer>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                    ))
                }
            </>
        )
    }

    render() {
        return (
            <>
                <Jumbotron className="jumb jumbotron-surroundings" fluid></Jumbotron>
                <Container className="mb-5">
                    <Row className="mb-3">
                        <Col className="mt-5">
                            <h3 className="text-dark-green font-bold-9">Možnosti sportovního a kulturního vyžití v okolí</h3>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <p>V okolních Železných horách je veliká síť turistických a cyklistických tras, které lze využít dle dostupných map a značených tras.</p>
                            <p>Doporučujeme se inspirovat: 
                                <ul>
                                    <li><a href="#tipy-na-vylet" className="hyperlink">Tipy na výlet v okolí</a></li>
                                    <li><a href="#tipy-na-cyklovylet" className="hyperlink">Tipy pro vyjížďku na kole</a></li>
                                </ul>
                            </p>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Zajímavosti z okolí</h3>
                            <div className="mt-3">
                                <p className="font-bold-6 mb-0">Turistické a cyklistické trasy</p>
                                <p className="font-size-13">- lze využít dle dostupných map a značených tras, v okolních Železných horách je veliká síť turistických a cyklistických tras</p>
                            </div>
                            <div className="mt-3">
                                <p className="font-bold-6 mb-0">Vodní nádrže a přehrady</p>
                                <p className="font-size-13 mb-0">- přehrada Seč je vzdálena 800 m, kde se lze věnovat vodním sportům a rybolovu</p>
                                <p className="font-size-13">- vodní nádrž Pařížov s nejstarší hrází z lomového kamene (11 km), možnost pouze rybolovu</p>
                            </div>
                            <div className="mt-3">
                                <p className="font-bold-6 mb-0">Historické památky</p>
                                <p className="font-size-13 mb-0">- zřícenina hradu Oheb (1,5 km)</p>
                                <p className="font-size-13 mb-0">- zřícenina hradu Lichnice (7 km)</p>
                                <p className="font-size-13 mb-0">- zámek Žleby (15 km)</p>
                                <p className="font-size-13 mb-0">- zámek Slatiňany (18 km)</p>
                                <p className="font-size-13">- historická města Kutná Hora (35 km), Čáslav (20 km), Chrudim (20 km) a Pardubice (30 km)</p>
                            </div>
                            <div className="mt-3">
                                <p className="font-bold-6 mb-0">Atrakce pro děti a dospělé</p>
                                <p className="font-size-13 mb-0">- u místní přehrady možnost využití půjčovny lodí, paddleboardů, šlapadel (0,5 km)</p>
                                <p className="font-size-13 mb-0">- u půjčovny lodiček je možné využít i plážového volejbalu (0,5 km)</p>
                                <p className="font-size-13 mb-0">- sportovní nadšenci mohou zkusit wakeboard případně diskgolf (0,5 km)</p>
                                <p className="font-size-13 mb-0">- tenisové kurty (1 km)</p>
                                <p className="font-size-13 mb-0">- wellness, venkovní a vnitřní bazén v kongresovém hotelu Jezerka (5 km)</p>
                                <p className="font-size-13 mb-0">- profesionální a 3D laserová střelnice v kongresovém hotelu Jezerka (5 km)</p>
                                <p className="font-size-13 mb-0">- bowling, squash a golfový trenažér v kongresovém hotelu Jezerka (5 km)</p>
                                <p className="font-size-13 mb-0">- fitness, herna a dětský koutek v kongresovém hotelu Jezerka (5 km)</p>
                                <p className="font-size-13 mb-0">- ornitologická stanice a obora bílých jelenů u zámku Žleby (15 km)</p>
                                <p className="font-size-13 mb-0">- Louisiana Ranch, zábavní park v Církvicích (30 km)</p>
                                <p className="font-size-13 mb-0">- nejdelší bobová dráha v ČR je postavena v Kutné Hoře (35 km)</p>
                                <p className="font-size-13">- Kutnohorská plovárna (35 km)</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-4" id="tipy-na-vylet">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Tipy na výlet</h3>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <a href="https://khfarnost.cz/cs/home-page-2-2-2/" title="Chrám svaté Barbory | Kutná Hora" className="font-size-13 font-roboto-condensed hyperlink-notunderline" target="_blank">
                                <Card className="card-hover">
                                    <Card.Img variant="top" src={thumb_barbora} />
                                    <Card.Body>
                                        <Card.Title className="text-dark-green mb-0">Chrám svaté Barbory</Card.Title>
                                        <Card.Text className="text-dark-gray">
                                            <p className="font-bold-6">Kutná Hora (35 km)</p>
                                            <hr></hr>
                                            <p className="mb-1">Římskokatolická farnost arciděkanství Kutná Hora...</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://www.sedlec.info/" title="Kostnice | Kutná Hora" className="font-size-13 font-roboto-condensed hyperlink-notunderline" target="_blank">
                                <Card className="card-hover">
                                    <Card.Img variant="top" src={thumb_kostnice} />
                                    <Card.Body>
                                        <Card.Title className="text-dark-green mb-0">Kostnice</Card.Title>
                                        <Card.Text className="text-dark-gray">
                                            <p className="font-bold-6">Kutná Hora (35 km)</p>
                                            <hr></hr>
                                            <p className="mb-1">Sedlecká kostnice je vyjádřením lidské smrtelnosti a víry ve vzkříšení..</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://www.cms-kh.cz/stredoveky-dul" title="České muzeum stříbra | Kutná Hora" className="font-size-13 font-roboto-condensed hyperlink-notunderline" target="_blank">
                                <Card className="card-hover">
                                    <Card.Img variant="top" src={thumb_stribro} />
                                    <Card.Body>
                                        <Card.Title className="text-dark-green mb-0">České muzeum stříbra</Card.Title>
                                        <Card.Text className="text-dark-gray">
                                            <p className="font-bold-6">Kutná Hora (35 km)</p>
                                            <hr></hr>
                                            <p className="mb-1">Návštěva středověkého dolu a muzea stříbra...</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://www.zamek-zleby.cz/cs" title="Státní zámek Žleby | Žleby" className="font-size-13 font-roboto-condensed hyperlink-notunderline" target="_blank">
                                <Card className="card-hover">
                                    <Card.Img variant="top" src={thumb_zleby} />
                                    <Card.Body>
                                        <Card.Title className="text-dark-green mb-0">Státní zámek Žleby</Card.Title>
                                        <Card.Text className="text-dark-gray">
                                            <p className="font-bold-6">Žleby (15 km)</p>
                                            <hr></hr>
                                            <p className="mb-1">Velkolepé romantické sídlo  s oborou bílých jelenů...</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <a href="https://www.nmvp.cz/vysocina/pro-navstevniky/prohlidka-expozicnich-arealu/vesely-kopec" title="Skanzen Veselý Kopec | Veselý Kopec" className="font-size-13 font-roboto-condensed hyperlink-notunderline" target="_blank">
                                <Card className="card-hover">
                                    <Card.Img variant="top" src={thumb_kopec} />
                                    <Card.Body>
                                        <Card.Title className="text-dark-green mb-0">Skanzen Veselý Kopec</Card.Title>
                                        <Card.Text className="text-dark-gray">
                                            <p className="font-bold-6">Veselý Kopec (20 km)</p>
                                            <hr></hr>
                                            <p className="mb-1">Ukázka hospodaření drobných zemědělců od 19. století...</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://lesychrudim.cz/" title="Rozhledna Bára | Chrudim" className="font-size-13 font-roboto-condensed hyperlink-notunderline" target="_blank">
                                <Card className="card-hover">
                                    <Card.Img variant="top" src={thumb_bara} />
                                    <Card.Body>
                                        <Card.Title className="text-dark-green mb-0">Rozhledna Bára</Card.Title>
                                        <Card.Text className="text-dark-gray">
                                            <p className="font-bold-6">Chrudim (20 km)</p>
                                            <hr></hr>
                                            <p className="mb-1">V areálu rozhledny naleznete i lanový park, singltrek...</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://www.puppets.cz/" title="Muzeum loutek | Chrudim" className="font-size-13 font-roboto-condensed hyperlink-notunderline" target="_blank">
                                <Card className="card-hover">
                                    <Card.Img variant="top" src={thumb_loutky} />
                                    <Card.Body>
                                        <Card.Title className="text-dark-green mb-0">Muzeum loutek</Card.Title>
                                        <Card.Text className="text-dark-gray">
                                            <p className="font-bold-6">Chrudim (20 km)</p>
                                            <hr></hr>
                                            <p className="mb-1">Muzeum dokumentuje vývoj českého a světového loutkářství...</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://www.vcm.cz/zamek/" title="Zámek Pardubice | Pardubice" className="font-size-13 font-roboto-condensed hyperlink-notunderline" target="_blank">
                                <Card className="card-hover">
                                    <Card.Img variant="top" src={thumb_pardubice} />
                                    <Card.Body>
                                        <Card.Title className="text-dark-green mb-0">Zámek Pardubice</Card.Title>
                                        <Card.Text className="text-dark-gray">
                                            <p className="font-bold-6">Pardubice (30 km)</p>
                                            <hr></hr>
                                            <p className="mb-1">Renesanční zámek s pávy, pánů z Pernštejna...</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-4" id="tipy-na-cyklovylet">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Tipy na cyklovýlet</h3>
                            <p className="font-size-13">Vyberte si jednu z našich, na vlastním těle ověřených, cyklistických tras. </p>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        {this.renderBikeTips()}
                    </Row>
                </Container>
            </>
        );
    }
}