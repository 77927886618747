import React from 'react';


import proverena_spolecnost from "../../images/proverena_spolecnost/2022.jpg";
import thumb_acc from "../../images/thumbnail/small_accommodation.png";
import thumb_around from "../../images/thumbnail/small_around.png";
import thumb_gallery from "../../images/thumbnail/small_gallery.png";
import thumb_pricelist from "../../images/thumbnail/small_pricelist.png";
import icon_hiking from "../../images/icons_home/hiking.png";
import icon_bike from "../../images/icons_home/bike.png";
import icon_fish from "../../images/icons_home/fishing.png";
import icon_swim from "../../images/icons_home/swim.png";
import icon_canoe from "../../images/icons_home/canoe.png";
import icon_wake from "../../images/icons_home/wake.png";
import icon_tenis from "../../images/icons_home/tenis.png";


import AppInfo from '../../data/AppInfo.json';
import ContactData from '../../data/Contact.json';
import CarouselData from '../../data/Carousel.json';

import { Container, Row, Col, Card, Carousel, ListGroup } from 'react-bootstrap';
import { Tooltip } from '@material-ui/core';

import EmailForm from '../common/EmailForm';

export default class Home extends React.Component {



    render() {
        return (
            <div className="mb-5">
                <Carousel className="mb-5">
                    {CarouselData.map((carouselImage, index) => (
                        <Carousel.Item>
                            <img className="d-block w-100" src={window.location.origin + carouselImage.url} alt={carouselImage.caption} />
                            <Carousel.Caption className="carousel-caption">
                                <h3>{carouselImage.caption}</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
                <Container>
                    <Row>
                        <Col>
                            <Container>
                                <Row>
                                    <Col>
                                        <h1 className="text-dark-gray font-bold-3">
                                            <strong><span>Apartmán</span><span className="text-green">Seč</span></strong>
                                        </h1>
                                        <span className="text-gray font-size-16">... rodinné ubytování u Sečské přehrady s vyžitím</span>
                                    </Col>
                                    <Col lg={7} className="text-right mt-3">
                                        <Tooltip title="Turistika" placement="top" arrow>
                                            <img src={icon_hiking} alt="Turistika" className="mr-1" />
                                        </Tooltip>
                                        <Tooltip title="Cyklistika" placement="top" arrow>
                                            <img src={icon_bike} alt="Cyklistika" className="mr-1" />
                                        </Tooltip>
                                        <Tooltip title="Rybolov" placement="top" arrow>
                                            <img src={icon_fish} alt="Rybolov" className="mr-1" />
                                        </Tooltip>
                                        <Tooltip title="Plavání" placement="top" arrow>
                                            <img src={icon_swim} alt="Plavání" className="mr-1" />
                                        </Tooltip>
                                        <Tooltip title="Půjčovna lodí" placement="top" arrow>
                                            <img src={icon_canoe} alt="Půjčovna lodí" className="mr-1" />
                                        </Tooltip>
                                        <Tooltip title="Wakeboard" placement="top" arrow>
                                            <img src={icon_wake} alt="Wakeboard" className="mr-1" />
                                        </Tooltip>
                                        <Tooltip title="Tenis" placement="top" arrow>
                                            <img src={icon_tenis} alt="Tenis" className="" />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr className="mt-4 mb-5"></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={7}>
                            <p>Apartmán Seč nabízí ubytování v srdci nádherných Železných hor, v městečku Seč.</p>
                            <p>V okolí lze využít velkého množství cyklistických a turistických výletů s možností osvěžení se v rozlehlé vodní nádrži. Sečská přehrada nabízí i nespočet vodních aktivit, např. zapůjčení loďky, šlapadla, paddleboardu nebo vyzkoušení wakeboardingu.</p>
                            <p>Ubytování se nachází v klidné části Seče.</p>
                            <p>Apartmán je v přízemí rodinného domu s dispozicí bytu 2+1 (kuchyň, obývací pokoj, ložnice, samostatné WC, koupelna a šatna). </p>
                            <p>Z apartmánu je krásný výhled do zahrady s terasou a grilem pro příjemné posezení.</p>
                            <p> Ke stravování je možné využít vlastní kuchyň nebo restaurace vzdálené cca 300m.</p>
                        </Col>
                        <Col lg={1}>

                        </Col>
                        <Col lg={4}>
                            <a href='https://www.proverenaspolecnost.cz/company,59083,apartman-sec' title='Jsme držiteli certifikátu Prověřená společnost' target='_blank'>
                                <img src={proverena_spolecnost} alt="Jsme držiteli certifikátu Prověřená společnost" className="image-shadow card-hover" />
                            </a>
                        </Col>

                    </Row>
                    <Row className="mt-3">
                        <Col className="mt-3">
                            <a className="font-size-13 font-roboto-condensed hyperlink-notunderline" href="/ubytovani">
                                <Card className="card-hover">
                                    <Card.Img variant="top" src={thumb_acc} />
                                    <Card.Body>
                                        <Card.Title className="text-dark-green">Ubytování</Card.Title>
                                        <Card.Text className="font-size-13 text-dark-gray">Podívejte se, co Vám naše ubytování nabízí a uznejte že je to to pravé pro Vaší dovolenou.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                        <Col className="mt-3">
                            <a className="font-size-13 font-roboto-condensed hyperlink-notunderline" href="/okoli">
                                <Card className="card-hover">
                                    <Card.Img variant="top" src={thumb_around} />
                                    <Card.Body>
                                        <Card.Title className="text-dark-green">Okolí</Card.Title>
                                        <Card.Text className="font-size-13 text-dark-gray">Prozkoumejte krásy a zajímavosti v našem okolí. Najděte si svůj zítřejšího programu.</Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                        <Col className="mt-3">
                            <a className="font-size-13 font-roboto-condensed hyperlink-notunderline" href="/fotogalerie">
                                <Card className="card-hover">
                                    <Card.Img variant="top" src={thumb_gallery} />
                                    <Card.Body>
                                        <Card.Title className="text-dark-green">Fotogalerie</Card.Title>
                                        <Card.Text className="font-size-13 text-dark-gray">A jak to u nás a v našem okolí vlastně vypadá? Nahlédněte na to co vás zde čeká. </Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                        <Col className="mt-3">
                            <a className="font-size-13 font-roboto-condensed hyperlink-notunderline" href="/cenik">
                                <Card className="card-hover">
                                    <Card.Img variant="top" src={thumb_pricelist} />
                                    <Card.Body>
                                        <Card.Title className="text-dark-green">Ceník</Card.Title>
                                        <Card.Text className="font-size-13 text-dark-gray">No a na kolik u nás to ubytování vyjde a jaké jsou podmínky našeho ubytování?</Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr className="mt-5 mb-5"></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Kontakt</h3>
                            <p>Napište nám, zavolejte, nejlépe přijeďte.</p>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <EmailForm></EmailForm>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr className="mt-5 mb-5"></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="text-dark-green font-bold-3">Kde nás najdete</h3>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <ListGroup>
                                <ListGroup.Item className="listgroup-header">Adresa</ListGroup.Item>
                                <ListGroup.Item className="font-size-14">{ContactData.address1}<br />{ContactData.address2}</ListGroup.Item>
                            </ListGroup>
                            <ListGroup className="mt-2 mb-2">
                                <ListGroup.Item className="listgroup-header">Kontakt</ListGroup.Item>
                                <ListGroup.Item className="font-size-14">tel: <a href={"tel: " + ContactData.mobile} className="hyperlink ml-3">{ContactData.mobile}</a></ListGroup.Item>
                                <ListGroup.Item className="font-size-14">email: <a href={'mailto:' + ContactData.email} className="hyperlink ml-3">{ContactData.email}</a></ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col lg={8}>
                            <iframe src="https://frame.mapy.cz/s/nogepomevo" allowFullScreen className="mapa" ></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}