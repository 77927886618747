import React from 'react';
import { Container, Jumbotron, Row, Col, ListGroup } from 'react-bootstrap';

import FastfoodOutlinedIcon from '@material-ui/icons/FastfoodOutlined';
import TvOutlinedIcon from '@material-ui/icons/TvOutlined';
import LocalHotelOutlinedIcon from '@material-ui/icons/LocalHotelOutlined';
import WcOutlinedIcon from '@material-ui/icons/WcOutlined';
import BathtubOutlinedIcon from '@material-ui/icons/BathtubOutlined';
import CardTravelOutlinedIcon from '@material-ui/icons/CardTravelOutlined';

import img_Bed from '../../images/icons/bed.png';
import img_Animals from '../../images/icons/do-not-animals.png';
import img_Smoking from '../../images/icons/no-smoking.png';
import img_Parking from '../../images/icons/parking.png';
import img_ParkingBike from '../../images/icons/parking-bycicle.png';
import img_Tv from '../../images/icons/television.png';
import img_Wifi from '../../images/icons/wifi.png';

export default class Accommodation extends React.Component {

    render() {
        return (
            <>
                <Jumbotron className="jumb jumbotron-accommo" fluid></Jumbotron>
                <Container className="mb-5">
                    <Row className="mb-3">
                        <Col className="mt-5">
                            <h3 className="text-dark-green font-bold-9">Ubytování</h3>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <p>Ubytování se nachází v klidné části Seče. </p>
                            <p>Apartmán je v přízemí rodinného domu s dispozicí bytu 2+1 (kuchyň, obývací pokoj, ložnice, samostatné WC, koupelna a šatna). Z apartmánu je krásný výhled do zahrady s terasou, grilem a ohništěm pro příjemné posezení.</p>
                            <p>Ke stravování je možné využít vlastní kuchyň nebo restaurace vzdálené cca 300m.</p>
                            <br></br>
                            <p>
                                <span className="font-bold-6 mr-4">Check in:</span> od 15:00 hod.
                                <br></br>
                                <span className="font-bold-6 mr-3">Check out:</span>do 10:00 hod.
                            </p>
                            <br></br>
                            <p className="font-bold-6">Možnost ubytování <span className="text-dark-green">od 1.6. do 30.9.</span> každého roku.</p>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={8}>
                            <h3 className="text-dark-green font-bold-3">Vybavení apartmánu</h3>
                            <div className="mt-3">
                                <p className="font-bold-6 mb-0">Kuchyň s jídelní částí</p>
                                <p className="font-size-13">Plně vybavena včetně varné konvice, lednice s mrazákem, mikrovlnné trouby, sporáku s varnou deskou, pečící troubou a elektrickým grilem.</p>
                            </div>
                            <div className="mt-3">
                                <p className="font-bold-6 mb-0">Obývací pokoj</p>
                                <p className="font-size-13">Rozkládací rohová sedací souprava, rádio a kabelová televize s plochou obrazovkou.</p>
                            </div>
                            <div className="mt-3">
                                <p className="font-bold-6 mb-0">Ložnice</p>
                                <p className="font-size-13">Manželská postel a patrová palanda včetně lůžkovin. K ložnici náleží i velká šatna, ve které je ukryt i trezor pro případné cennosti.</p>
                            </div>
                            <div className="mt-3">
                                <p className="font-bold-6 mb-0">Koupelna</p>
                                <p className="font-size-13">Vana a sprchový kout.</p>
                            </div>
                            <div className="mt-3">
                                <p className="font-bold-6 mb-0">Venkovní posezení</p>
                                <p className="font-size-13">Venkovní terasa s možností el. grilování, ohniště a pro děti je zde připravena i trampolína.</p>
                            </div>
                        </Col>
                        <Col>
                            <ListGroup>
                                <ListGroup.Item className="listgroup-header font-size-14">Dispozice apartmánu</ListGroup.Item>
                                <ListGroup.Item className="font-size-13">
                                    <FastfoodOutlinedIcon className="mr-3"></FastfoodOutlinedIcon>Kuchyně
                                </ListGroup.Item>
                                <ListGroup.Item className="font-size-13">
                                    <TvOutlinedIcon className="mr-3"></TvOutlinedIcon>Obývací pokoj
                                </ListGroup.Item>
                                <ListGroup.Item className="font-size-13">
                                    <LocalHotelOutlinedIcon className="mr-3"></LocalHotelOutlinedIcon>Ložnice s manželskou a patrovou postelí
                                </ListGroup.Item>
                                <ListGroup.Item className="font-size-13">
                                    <WcOutlinedIcon className="mr-3"></WcOutlinedIcon>Samostatné WC
                                </ListGroup.Item>
                                <ListGroup.Item className="font-size-13">
                                    <BathtubOutlinedIcon className="mr-3"></BathtubOutlinedIcon>Koupelna s vanou a sprchovým koutem
                                </ListGroup.Item>
                                <ListGroup.Item className="font-size-13">
                                    <CardTravelOutlinedIcon className="mr-3"></CardTravelOutlinedIcon>Šatna
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row className="mb-4 font-size-13 text-center font-bold-6 text-dark-green">
                        <Col>
                            <img src={img_Bed} width={40} height={40} className="mb-2" alt="1-6 osob"></img>
                            <p>1-6 osob</p>
                        </Col>
                        <Col>
                            <img src={img_Wifi} width={40} height={40} className="mb-2" alt="Wifi"></img>
                            <p>Wifi</p>
                        </Col>
                        <Col>
                            <img src={img_Tv} width={40} height={40} className="mb-2" alt="Kabelová TV"></img>
                            <p>Kabelová TV</p>
                        </Col>
                        <Col>
                            <img src={img_Parking} width={40} height={40} className="mb-2" alt="Parkování u apartmánu"></img>
                            <p>Parkování u apartmánu</p>
                        </Col>
                        <Col>
                            <img src={img_ParkingBike} width={40} height={40} className="mb-2" alt="Uskladnění kol"></img>
                            <p>Uskladnění kol</p>
                        </Col>
                        <Col>
                            <img src={img_Smoking} width={40} height={40} className="mb-2" alt="Nekuřácké"></img>
                            <p>Nekuřácké</p>
                        </Col>
                        <Col>
                            <img src={img_Animals} width={40} height={40} className="mb-2" alt="Bez zvířat"></img>
                            <p>Bez zvířat</p>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}