import React from 'react';
import { NavLink } from 'react-router-dom';

import { Container, Row, Col, Jumbotron, Card, ListGroup, ListGroupItem } from 'react-bootstrap';

import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';

import AppInfo from '../../data/AppInfo.json';
import ContactData from '../../data/Contact.json';

export default class Footer extends React.Component {

    render() {
        return (
            <Jumbotron className="mb-0 pb-0 pt-4 border-top font-size-11 bg-footer" fluid>
                <Container className="pb-2">
                    <Row>
                        <Col lg={3} className="mt-2">
                            <p><NavLink className="font-size-13 font-roboto-condensed hyperlink" to="/ubytovani">UBYTOVÁNÍ</NavLink></p>
                            <p><NavLink className="font-size-13 font-roboto-condensed hyperlink" to="/okoli">OKOLÍ</NavLink></p>
                            <p><NavLink className="font-size-13 font-roboto-condensed hyperlink" to="/fotogalerie">FOTOGALERIE</NavLink></p>
                        </Col>
                        <Col lg={3} className="mt-2">
                            <p><NavLink className="font-size-13 font-roboto-condensed hyperlink" to="/cenik">CENÍK</NavLink></p>
                            <p><NavLink className="font-size-13 font-roboto-condensed hyperlink" to="/reference">REFERENCE</NavLink></p>
                            <p><NavLink className="font-size-13 font-roboto-condensed hyperlink" to="/kontakt">KONTAKT</NavLink></p>
                        </Col>
                        <Col lg={3} className="mt-2">
                            <p className="font-size-13 font-weight-bold font-roboto-condensed">Kontakt</p>
                            <p>
                                <PhoneOutlinedIcon className="mr-1" style={{ fontSize: "18px" }}></PhoneOutlinedIcon>
                                <a href={"tel: " + ContactData.mobile} className="hyperlink">{ContactData.mobile}</a>
                                <br />
                                <AlternateEmailOutlinedIcon className="mr-1" style={{ fontSize: "18px" }}></AlternateEmailOutlinedIcon>
                                <a href={'mailto:' + ContactData.email} className="hyperlink">{ContactData.email}</a>
                                <br />
                                <LanguageOutlinedIcon className="mr-1" style={{ fontSize: "18px" }}></LanguageOutlinedIcon>
                                <a href={AppInfo.Url} className="hyperlink">{AppInfo.Url}</a>
                            </p>
                            <br></br>
                            <p><NavLink className="mt-0 pt-0 hyperlink" to="/ostatni/prohlaseni-o-pouzivani-cookies">Prohlášení o používání cookies</NavLink></p>
                        </Col>
                        <Col lg={3}>
                            <Card>
                                <Card.Body style={{ padding: "0" }}>
                                    <ListGroup className="list-group-flush text-secondary">
                                        {ContactData.portals.map((item, index) => (
                                            <ListGroupItem key={index}>
                                                <a rel="noopener noreferrer" key={index} href={item.url} target="_blank" className="btn-sm text-secondary text-underline font-size-12">{item.name}</a>
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <p className="small">{AppInfo.Description}</p>
                    </Row>
                </Container>
            </Jumbotron>
        );
    }
}